/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    br: "br",
    a: "a",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    img: "img"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/trying-mini-ix.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ll hear everything, but they’ll see nothing. As one of the smallest in the world, the Horizon Mini IX hearing aid disappears completely inside your ear while still ensuring brilliant sound and maximum comfort. Plus, this ", React.createElement(_components.strong, null, "incredibly tiny hearing aid"), " is personalized to your individual needs so you’ll finally be able to hear everything you’ve been missing.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " developed the entire line of ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX hearing aids"), " to naturally open up a world of sound to those with hearing loss. All of the Horizon ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ", including the Horizon Mini IX, are helping people stay active and feel younger, longer, thanks to specialized technology developed by world-class engineers. The Horizon Mini IX hearing aids are ultra discreet, extremely powerful, and provide the best natural sound to keep you on the go and hearing life the way it was meant to be heard.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "horizon-mini-ix-benefits-and-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-mini-ix-benefits-and-features",
    "aria-label": "horizon mini ix benefits and features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Mini IX benefits and features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ready to hear the world better? The Horizon Mini IX uses pioneering IX platform technology for incredible sound clarity unheard of in a ", React.createElement(_components.strong, null, "small hearing aid"), ". But these nearly invisible hearing aids go further, boasting extra benefits that deliver a second-to-none hearing experience."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Virtually invisible fit:"), " Completely disappears inside your ear, so no one will know your secret."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Innovative Speech Focus:"), " Boost speech clarity to a higher level and filter out background noise with a quick touch of a button, so you can focus on the person talking with you — not other people in the room."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Soothing Relax Mode:"), " Peace and quiet never came so easy. Sit back and relax by streaming soothing soundscapes right through your Horizon Mini IX hearing aids."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Easy-to-use smartphone app:"), " Discreetly control your volume and change and boost settings right from the palm of your hand."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Convenient rechargeability:"), " As the world’s first rechargeable completely-in-canal (CIC) hearing aid of this size, this device makes it easy to charge at night and wear all day, thanks to the sleek, portable charging case. The rechargeable battery goes up to 28 hours fully charged and has a 30-minutes quick charge option to go for 6 more hours."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "CROS & BiCROS available:"), " Compatible with all technologies. Has the lowest system latency in the industry. Provides a smoother sound experience and improves localization."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Binaural OneMic Directionality:"), " The only CIC hearing aid that offers this feature to focus on speaker in noisy environments."), "\n"), "\n"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "choose-your-perfect-match",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#choose-your-perfect-match",
    "aria-label": "choose your perfect match permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Choose your perfect match"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon Mini IX hearing aids come in two neutral colors of black and mocha for ultra blending and discretion. But remember, this nearly invisible hearing aid disappears inside your ear so no one will be able to see it unless you want them to."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "is-the-horizon-mini-ix-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#is-the-horizon-mini-ix-right-for-you",
    "aria-label": "is the horizon mini ix right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Is the Horizon Mini IX right for you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Getting the right hearing aid for your needs is vital to your happiness. We’re here to help determine the best hearing aid for your hearing loss and lifestyle. The entire Horizon IX hearing aid line meets your most active needs and delivers awesome performance in any situation. And the Horizon Mini IX is no exception. If you have mild to moderate hearing loss and are looking for a ", React.createElement(_components.strong, null, "small hearing aids"), " that offers the best speech clarity and is nearly invisible when you’re wearing it, look no further than the Horizon Mini IX."), "\n", React.createElement("video", {
    className: "o-fluid-img",
    src: "https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:best/v1/assets/na/seo-website/video/dave-mini.mp4",
    poster: "https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:best/v1/assets/na/seo-website/video/dave-mini.jpg",
    autoPlay: true,
    loop: true,
    playsInline: true,
    muted: true
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Our history")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Driven to make hearing care more accessible for everyone around the world, we’ve helped hundreds of thousands of people over the last decade understand and act on their hearing loss so they can experience a fuller life. Here’s how we’ve grown:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/about-us-timeline-desktop.png",
    alt: "hear.com story timeline",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-makes-us-different-from-the-rest",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-makes-us-different-from-the-rest",
    "aria-label": "what makes us different from the rest permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What makes us different from the rest"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing, health and wellness matter to us. That’s why after millions of consultations, we developed a formula for success to ensure people with hearing loss have the right support and care when making the important investment into better hearing. We discovered that only when you combine best-in-class hearing aids with top professional care can you guarantee the best outcomes for those with hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We don’t just give you hearing aids and hope for the best. Our Hearing Success ProgramTM guides and supports you throughout your 45-day trial, one day at a time. It takes about 30 days to get used to new hearing aids, and we’ll be with you every step of the way. We can’t wait to hear you say what our other happy customers say… “I wish I had gotten these sooner!”"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-ear-care-near-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-ear-care-near-you",
    "aria-label": "expert ear care near you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert ear care near you"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing care should be convenient. That’s why our expert audiologists and hearing experts are located right in your neighborhood. We only work with the top 2% of hearing professionals in the country to guarantee your best results. The Horizon Mini IX hearing aids must be professionally programmed by a professional to make a real difference in your life. Only when you combine superior technology with first-rate professionals will you get the best outcome. We’re here for you!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try Horizon Mini IX hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
